<template>
  <div v-if="formAction === 'select'" style="padding: 20px">
    <span v-if="contentFormInfo.event_name !== ''">
      <div class="titles">{{ __("Event") }}</div>
      <div class="details">{{ contentFormInfo.event_name }}</div>
    </span>
    <div v-if="contentFormInfo.event_details">
      <div
        v-for="event_detail in contentFormInfo.event_details"
        :key="event_detail.title"
      >
        <div class="titles">{{ __(event_detail.title) }}</div>
        <div class="details">{{ formattedDateTime(event_detail.detail) }}</div>
      </div>
    </div>
    <div v-else>
      <div class="titles">{{ __("Updated at") }}</div>
      <div class="details">
        {{ formattedDateTime(event_detail.updated_at) }}
      </div>
    </div>
    <div v-if="contentFormInfo.additional_info">
      <div
        v-for="additional_detail in contentFormInfo.additional_info"
        :key="additional_detail.title"
      >
        <div class="titles">{{ __(additional_detail.title) }}</div>
        <div v-if="additional_detail.is_date_time" class="details">
          {{ formattedDateTime(additional_detail.detail) }}
        </div>
        <div v-else class="details">
          {{ additional_detail.detail }}
        </div>
      </div>
    </div>
    <div
      v-if="
        contentFormInfo.causer !== undefined && contentFormInfo.causer !== null
      "
    >
      <div class="titles">{{ __("User ID") }}</div>
      <div class="details">{{ contentFormInfo.causer.user_id }}</div>

      <div class="titles">{{ __("User Name") }}</div>
      <div class="details">{{ contentFormInfo.causer.display_name }}</div>

      <div class="titles">{{ __("Email") }}</div>
      <div class="details">{{ contentFormInfo.causer.email }}</div>
    </div>
  </div>
</template>

<script>
import BaseContentInfoPanel from "@/views/build/content/mixins/BaseContentInfoPanel";
import BaseContent from "@/views/build/content/mixins/BaseContent";

import { mapGetters } from "vuex";

export default {
  mixins: [BaseContent, BaseContentInfoPanel],
  computed: {
    ...mapGetters("app", {
      formattedDateTime: "formattedDateTime"
    })
  },
  methods: {
    parse(json) {
      return JSON.parse(json);
    }
  }
};
</script>

<style scoped lang="scss">
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;
$content-theme-outline-color: var(--theme-outline-color) !default;
@import "~@/styles/content-edit-info.scss";
@import "~@/styles/tags.scss";
@import "~@/styles/node_common.scss";
</style>
