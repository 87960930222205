<script>
import List from "./pages/list";
import createOrEdit from "./pages/createOrEdit";
import BaseIndex from "@/views/build/content/mixins/BaseIndex";
import { mapState } from "vuex";

export default {
  name: "ActivityLogsIndex",
  mixins: [BaseIndex],
  components: {
    List,
    createOrEdit
  },

  data() {
    return {
      selectedContent: this.initContent()
    };
  },

  computed: {
    ...mapState("activitylogs", {
      contents: state => state.activityLogs
    }),
    ...mapState("app", {
      userApplicationViewScope: state => state.userApplicationViewScope
    })
  },

  methods: {
    initContent() {
      return {};
    }
  },

  mounted() {
    this.contentType = "Activity Log";
    this.primaryKey = "id";
  },

  watch: {
    userApplicationViewScope: "initContent"
  }
};
</script>

<style lang="scss" scoped></style>
