<template>
  <div style="width: 100%;" @click="handleClear">
    <page-header style="padding: 20px 30px" :title="__('User Activity')" />
    <div style="padding:0 36px" v-loading="isLoading">
      <el-form class="form-container">
        <PaginationToolbar
          :sort-by-options="sortByOptions"
          :search-options="JSON.stringify(searchOptions)"
          :content-api="contentAPI"
          :show-slot="true"
          @success="$emit('replaceContent', $event)"
        >
          <el-button
            class="advancedBtn"
            slot="buttonGroup"
            @click="dialogVisible = true"
          >
            <i
              class="el-icon-s-operation"
              style="font-weight: 600;font-size: larger;margin-left: -7px;"
            >
            </i>
          </el-button>
        </PaginationToolbar>
      </el-form>
      <el-dialog
        :title="__('Advanced Filter')"
        :visible.sync="dialogVisible"
        width="40%"
        destroy-on-close
      >
        <div class="account-form-dialog">
          <el-scrollbar :native="false">
            <el-form
              ref="contentForm"
              v-model="searchOptions"
              label-position="top"
              size="large"
              hide-required-asterisk
            >
              <el-row type="flex">
                <el-col :span="16">
                  <el-form-item :label="__('Event Type')" prop="log_name">
                    <el-select
                      value-key="sp_id"
                      v-model="searchOptions.log_name"
                      default-first-option
                      filterable
                      clearable
                      :placeholder="__('Select')"
                      style="width: 100%"
                      @change="onChangeOption($event)"
                    >
                      <el-option-group
                        v-for="(events, subject_type) in groupedEventNames"
                        :key="subject_type"
                        :label="__(subjectType(subject_type))"
                      >
                        <el-option
                          v-for="event in events"
                          :key="event.id"
                          :label="__(event.label.toLowerCase().trim())"
                          :value="event.label"
                        >
                        </el-option>
                      </el-option-group>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row type="flex" v-if="searchOptions.properties.length">
                <el-col :span="16">
                  <el-form-item
                    v-for="property in searchOptions.properties"
                    :key="property.key"
                    :label="property.label"
                  >
                    <el-input
                      v-if="property.type === 'text'"
                      v-model="property.value"
                      :placeholder="__('Enter your text')"
                    />
                    <el-select
                      v-if="property.type === 'dropdown'"
                      v-model="property.value"
                      default-first-option
                      clearable
                      :placeholder="__('Select value')"
                      @change="handleSelectUpdate($event)"
                    >
                      <el-option
                        v-for="item in property.data"
                        :key="item"
                        :label="item"
                        :value="item"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row type="flex">
                <el-col :span="16">
                  <el-form-item
                    class="form_label_top"
                    :label="__('Start At')"
                    prop="searchOptions.date_range"
                  >
                    <div slot="label" class="form-label">
                      <div>{{ __("Date/Time Range:") }}</div>
                      <div>
                        <el-popover
                          placement="top-start"
                          width="400"
                          trigger="hover"
                          :content="__('filter events by date range')"
                        >
                          <i slot="reference" class="el-icon-info icon"></i>
                        </el-popover>
                      </div>
                    </div>
                    <el-date-picker
                      v-model="searchOptions.date_range"
                      type="datetimerange"
                      value-format="yyyy-MM-dd HH:mm:ss"
                      :format="displayDateTimeSecondsFormat"
                      align="right"
                      unlink-panels
                      :range-separator="__('To')"
                      :start-placeholder="__('Start date')"
                      :end-placeholder="__('End date')"
                      :picker-options="pickerOptions"
                    >
                    </el-date-picker>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </el-scrollbar>
        </div>
      </el-dialog>
      <el-scrollbar :native="true">
        <div class="contentListContainer">
          <el-table
            ref="activityLogTable"
            class="list-table"
            :data="activityLogs"
            highlight-current-row
            @row-click="handleSingleClick"
            @row-dblclick="handleDoubleClick"
          >
            <el-table-column
              :label="__('Event Description')"
              prop="description"
            >
              <template slot-scope="scope">
                <div v-if="scope.row.event_url">
                  <el-row
                    type="flex"
                    justify="flex-start"
                    style="align-items: center"
                  >
                    <!--<el-link
                        type="primary"
                        :href="'/' + scope.row.event_url"
                        target="_blank"
                        :underline="false"
                        >{{ scope.row.event_name }}</el-link
                      >-->
                    <span>{{ scope.row.description }}</span>
                  </el-row>
                </div>
                <div v-else>
                  <span>{{ scope.row.description }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column :label="__('Date')" prop="event_time">
              <template slot-scope="scope">
                <el-tooltip
                  class="item"
                  placement="right"
                  :content="convertTSToDateTime(scope.row.created_at)"
                >
                  <span>{{ scope.row.event_time }}</span>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-scrollbar>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import PaginationToolbar from "@/components/PaginationToolbar";
import PageHeader from "@/components/PageHeader";
import BaseContent from "@/views/build/content/mixins/BaseContent";
import _ from "lodash";
import { formatTimeStampWithTz } from "@/utils/time";

export default {
  name: "ActivityLogsList",
  mixins: [BaseContent],
  components: {
    PaginationToolbar,
    PageHeader
  },
  props: {
    msg: {
      required: false,
      type: String,
      default: ""
    }
  },
  data() {
    return {
      fetchedActivityLogs: false,
      sortByColumns: ["description", "id"],
      dialogVisible: false,
      searchOptions: {
        log_name: "",
        date_range: "",
        properties: {}
      },
      sortColumnLabel: __("Description"),
      availableSortOptions: ["asc|desc", "old|new"],
      pickerOptions: {
        shortcuts: [
          {
            text: __("Last week"),
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: __("Last month"),
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: __("Last 3 months"),
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            }
          }
        ]
      }
    };
  },

  computed: {
    subjectType() {
      return subject_type => {
        return subject_type ? subject_type.split(/[\\]+/).pop() : __("Other");
      };
    },
    ...mapState("activitylogs", {
      activityLogs: state => state.activityLogs,
      eventNames: state => state.eventNames,
      isLoading: state => state.isLoading
    }),

    ...mapState("app", {
      userApplicationViewScope: state => state.userApplicationViewScope,
      selectedServiceProviderId: state => state.selectedServiceProviderId,
      displayDateTimeSecondsFormatMoment: "displayDateTimeSecondsFormatMoment",
      timezone: state => state.timezone
    }),

    ...mapGetters("app", {
      displayDateTimeSecondsFormat: "displayDateTimeSecondsFormat"
    }),

    ...mapGetters("activitylogs", {
      groupedEventNames: "groupedEventNamesBySubject"
    })
  },

  mounted() {
    if (this.msg) {
      this.$message({
        type: "success",
        message: this.msg
      });
    }
    this.getActivityLogEventNames();
  },
  methods: {
    ...mapActions("activitylogs", {
      contentAPI: "getActivityLogs",
      getActivityLogEventNames: "getActivityLogEventNames"
    }),

    async initializeContents() {
      this.fetchedActivityLogs = false;
      if (!this.msg && this.selectedServiceProviderId !== "all") {
        // no need to retrieve all records as the list is paginated
        await this.contentAPI({
          per_page: 24,
          page: 1,
          orderBy: "id",
          sortedBy: "desc"
        });
      }
      this.fetchedActivityLogs = true;
    },

    handleSingleClick(row, column, event) {
      event.stopPropagation();
      this.handleSelect(null, row);
    },

    handleDoubleClick(row, column, event) {
      event.stopPropagation();
    },

    handleClear() {
      this.$refs.activityLogTable.setCurrentRow();
      this.handleClearSelection();
    },
    onChangeOption(label) {
      if (label) {
        let properties = _.find(this.eventNames, event => {
          return event.label === label;
        }).properties;
        this.$set(this.searchOptions, "properties", properties);
        console.log("properties:", properties);
      } else {
        this.$set(this.searchOptions, "properties", {});
      }
    },
    convertTSToDateTime(dateTimeVal) {
      return formatTimeStampWithTz(
        dateTimeVal,
        this.timezone,
        this.displayDateTimeSecondsFormatMoment
      );
    }
  },

  watch: {
    userApplicationViewScope: "initializeContents"
  }
};
</script>

<style lang="scss" scoped>
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;
$content-theme-outline-color: var(--theme-outline-color) !default;
$card-image-filter: invert(65%) sepia(43%) saturate(5014%) hue-rotate(170deg)
  brightness(104%) contrast(89%);
@import "~@/styles/content-buttons.scss";
@import "~@/styles/content-list.scss";
@import "~@/styles/pagination-toolbar.scss";

.advancedBtn {
  margin-left: 20px;
  height: 44px;
  width: 30px;
  border-color: #f5f5f8;
  color: #a0a8b5;
  &:hover {
    background-color: transparent;
    border-color: $content-theme-color;
    ::v-deep svg {
      fill: $content-theme-hover-color;
      path {
        fill: $content-theme-hover-color;
      }
    }
  }
  &.is-disabled:hover,
  &.is-disabled {
    color: #a0a8b5;
    border-color: #f5f5f8;
    ::v-deep svg {
      fill: #a0a8b5;
      path {
        fill: #a0a8b5;
      }
    }
  }
}
</style>
